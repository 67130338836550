import React, { useState, useEffect, useRef } from 'react';
import './admin_ads_view.css';
import { Col, Row, Image, Card, Carousel, Spinner, Button, Breadcrumb } from "react-bootstrap";
import Container from "react-bootstrap/Container"
import LocalIP from "../../LocalIP";
import axios from "axios"
import { FaArrowAltCircleLeft, FaWhatsapp, FaPhoneAlt } from 'react-icons/fa'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import { MdFavorite } from "react-icons/md"
import { useHistory } from 'react-router-dom';
import { MdFavoriteBorder } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import { FaShieldAlt } from 'react-icons/fa';
import swal from 'sweetalert'
import { IoMdCloseCircle } from "react-icons/io";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { IoTrashBin } from "react-icons/io5";

function Admin_ads_view(props) {

  const history = useHistory();
  const [mainImgUrl, setMainImgUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const [adsData, setAdsData] = useState([]);
  const [currentImgNumber, setCurrentImgNumber] = useState('');
  const [imgCount, setImgCount] = useState();
  const [myFavorite, setMyFavorite] = useState(false);
  useEffect(() => onReload(), []);

  const onReload = () => {
    console.log("new")
    userCheck()
  };

  const userCheck = () => {
    if (localStorage.getItem("loginAccess")) {
      if (localStorage.getItem("privilege") * 1 === 1) {

        const url1 = LocalIP + "ads/admin/one/" + props.match.params.id;
        axios.get(url1, {
          headers: { "Content-Type": "application/json", Authorization: localStorage.getItem('token') }
        }).then((response) => {
          console.log(response["data"][0])
          if (response["data"][0]['AD_TITLE'] === null && response["data"][0]['CATEGORY'] === null && response["data"][0]['AREA'] === null && response["data"][0]['IMG_1'] === null) {
            history.push("/");
          }
          setAdsData(response["data"][0])
          setLoading(false)
          setMainImgUrl(response["data"][0]['IMG_1'])
          setCurrentImgNumber(1)
          if (response["data"][0]['IMG_5'] != "") {
            setImgCount(5)
          } else if (response["data"][0]['IMG_4'] != "") {
            setImgCount(4)
          } else if (response["data"][0]['IMG_3'] != "") {
            setImgCount(3)
          } else if (response["data"][0]['IMG_2'] != "") {
            setImgCount(2)
          } else {
            setImgCount(1)
          }
        });

        const url = LocalIP + "ads/checkMyFavorite";
        axios
          .get(url, {
            params: {
              user_id: localStorage.getItem("id"),
              ad_id: props.match.params.id
            },
            headers: {
              'Content-Type': 'application/json'
            }
          })
          .then(async (res) => {
            console.log(res.data);
            if (res.data.success === "success") {
              setMyFavorite(true)
            } else {
              setMyFavorite(false)
            }
          });
      } else {
        history.push("/");
        return 0
      }
    } else {
      history.push("/");
      return 0
    }
  }

  const goBack = () => {
    history.goBack();
  };

  const whatsappClick = (number) => {
    swal({
      title: "Warning",
      content: {
        element: "span",
        attributes: {
          innerHTML: 'Exercise caution and due-diligence when going ahead with the rental, trading or service. <a href="/disclaimer" target="_blank">See more</a>'
        }
      },
      icon: "warning",
      buttons: ["Cancel", "OK"],
      dangerMode: true,
    }).then((willOK) => {
      if (willOK) {
        window.open("https://wa.me/" + number, '_blank');
      }
    });
  }

  const callClick = (number) => {
    swal({
      title: "Warning",
      content: {
        element: "span",
        attributes: {
          innerHTML: 'Exercise caution and due-diligence when going ahead with the rental, trading or service. <a href="/disclaimer" target="_blank">See more</a>'
        }
      },
      icon: "warning",
      buttons: ["Cancel", "OK"],
      dangerMode: true,
    }).then((willOK) => {
      if (willOK) {
        window.open("tel:+" + number, '_blank');
      }
    });
  }

  const adMyFavorite = async (bool) => {
    if (localStorage.getItem("loginAccess") != null) {
      const url = LocalIP + "ads/myFavorite";
      const data = JSON.stringify({
        user_id: localStorage.getItem("id"),
        ad_id: props.match.params.id
      });
      console.log(data);
      await axios
        .post(url, data, {
          headers: { "Content-Type": "application/json" },
        })
        .then(async (res) => {
          console.log(res.data);
          if (res.data.success === "success") {
            toast.success("Added to My Favorites!", {
              position: "top-right",
            });
          } else {
            toast.error("Remove My Favorite!", {
              position: "top-right",
            });
          }
        });
      if (bool) {
        setMyFavorite(false)
      } else {
        setMyFavorite(true)
      }
    } else {
      toast.error("Please Login!", {
        position: "top-right",
      });
    }
  };

  const setMainImage = async (imgNumber) => {
    if (imgNumber == 1) {
      setMainImgUrl(adsData['IMG_1'])
    } else if (imgNumber == 2) {
      setMainImgUrl(adsData['IMG_2'])
    } else if (imgNumber == 3) {
      setMainImgUrl(adsData['IMG_3'])
    } else if (imgNumber == 4) {
      setMainImgUrl(adsData['IMG_4'])
    } else {
      setMainImgUrl(adsData['IMG_5'])
    }
  }

  const nextImg = async () => {
    if (imgCount != currentImgNumber) {
      await setCurrentImgNumber(currentImgNumber + 1)
      setMainImage(currentImgNumber + 1)
    } else {
      await setCurrentImgNumber(1)
      setMainImage(1)
    }
  }

  const previousImg = async () => {
    if (currentImgNumber != 1) {
      await setCurrentImgNumber(currentImgNumber - 1)
      setMainImage(currentImgNumber - 1)
    } else {
      await setCurrentImgNumber(imgCount)
      setMainImage(imgCount)
    }
  }

  const adsImgClick = async (id) => {
    setMainImage(id)
    setCurrentImgNumber(id)
  }

  const approveAd = async (id, fname, email) => {
    console.log(fname)
    console.log(email)
    const url = LocalIP + "ads/pending_approve_ads"; const data = JSON.stringify({
      fname: fname,
      email: email,
      id: id
    });
    console.log(url)
    await axios
      .put(url, data, {
        headers: { "Content-Type": "application/json", Authorization: localStorage.getItem('token') },
      })
      .then(async (res) => {
        console.log(res.data);
        if (res.data.success === "success") {
          toast.success("Approved Successfully!", {
            position: "top-right",
          });
          onReload()
        }
      });
  }

  const rejectAd = async (id, fname, email) => {
    const url = LocalIP + "ads/pending_reject_ads";
    console.log(url)
    await axios
      .delete(url, {
        headers: { "Content-Type": "application/json", Authorization: localStorage.getItem('token') }, params: {
          id: id,
          fname: fname,
          email: email
        }
      })
      .then(async (res) => {
        console.log(res.data);
        if (res.data.success === "success") {
          toast.success("Ads Rejected!", {
            position: "top-right",
          });
          onReload()
        }
      });
  }

  const posted_date_time = (dateTime) => {
    const dateObj = new Date(dateTime);
    const now = new Date();

    const options = { day: 'numeric', month: 'short' };

    if (dateObj.getFullYear() !== now.getFullYear()) {
      options.year = 'numeric';
    }

    const formattedDate = dateObj.toLocaleDateString('en-GB', options);

    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'pm' : 'am';
    const formattedTime = `${hours % 12 || 12}:${minutes} ${ampm}`;

    return `Posted on ${formattedDate} ${formattedTime}`;
  }

  const deleteAd = async (id) => {
    const url = LocalIP + "ads/remove_ad/" + id
    console.log(url)
    await axios
      .delete(url, {
        headers: { "Content-Type": "application/json", Authorization: localStorage.getItem('token') }
      })
      .then(async (res) => {
        console.log(res.data);
        if (res.data.success === "success") {
          toast.success("Advertisement remove successfully!", {
            position: "top-right",
          });
          onReload()
        }
      });
  }

  return (
    <Container>
      <br />
      <div id="admin_ads_view" className="web-view">
        {loading ? (<Spinner animation="border" />) : (
          <div className='ad-view-container full-ads-div'>
            <Row>
              <Col md={8}>
                <Row>
                  <Col md={1}></Col>
                  <Col md={10}>

                    <Breadcrumb className="breadcrumb-custom">
                      <Breadcrumb.Item href="/" >Home</Breadcrumb.Item>
                      <Breadcrumb.Item href="/all_ads" >All ads</Breadcrumb.Item>
                      <Breadcrumb.Item href={"/all_ads?area=" + adsData['AREA']} >{adsData['AREA'] == 1 ? ("Sharjah") : ("Abu Dhabi")}</Breadcrumb.Item>
                      <Breadcrumb.Item href={"/all_ads?category=" + adsData['CATEGORY'] + "&area=" + adsData['AREA']} >{adsData['category_name']}</Breadcrumb.Item>
                    </Breadcrumb>
                  </Col>
                  <Col md={1}></Col>
                </Row>
              </Col>
              <Col className="d-flex flex-column align-items-end" md={4}>
                <FaArrowAltCircleLeft className='ads-icons' onClick={goBack} />
              </Col>
            </Row>
            <Row>
              <Col md={8}>
                <Row>
                  <Col md={1}></Col>
                  <Col md={10}>
                    <p className='ads-title-left'>{adsData['AD_TITLE']}</p>
                    <p className='posted-date-text-left'><span className='posted_date_time'>{posted_date_time(adsData['date_time'])}</span></p>
                  </Col>
                  <Col md={1}></Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={8}>
                <Row className="justify-content-center align-items-center" >
                  <Col md={10} className="card d-flex justify-content-end ads-div-color">
                    <p className='main-image-count-text'>{currentImgNumber + "/" + imgCount}</p>
                    <Image src={mainImgUrl} fluid className='ads-view-main-image' />
                  </Col>
                </Row>
                <Row className="justify-content-center mt-3">
                  <Col xs={1} md={1} >
                    <IoIosArrowBack className='ads-image-arrow' onClick={previousImg} />
                  </Col>
                  {
                    adsData['IMG_1'] && (
                      <Col xs={2} md={2} >
                        <Image
                          src={adsData['IMG_1']}
                          thumbnail
                          className={currentImgNumber === 1 ? 'border border-dark ads-view-small-image' : 'ads-view-small-image'}
                          onClick={() => adsImgClick(1)}
                        />
                      </Col>
                    )
                  }
                  {
                    adsData['IMG_2'] && (
                      <Col xs={2} md={2} >
                        <Image
                          src={adsData['IMG_2']}
                          thumbnail
                          className={currentImgNumber === 2 ? 'border border-dark ads-view-small-image' : 'ads-view-small-image'}
                          onClick={() => adsImgClick(2)}
                        />
                      </Col>)
                  }
                  {
                    adsData['IMG_3'] && (
                      <Col xs={2} md={2} >
                        <Image
                          src={adsData['IMG_3']}
                          thumbnail
                          className={currentImgNumber === 3 ? 'border border-dark ads-view-small-image' : 'ads-view-small-image'}
                          onClick={() => adsImgClick(3)}
                        />
                      </Col>)
                  }
                  {
                    adsData['IMG_4'] && (
                      <Col xs={2} md={2} >
                        <Image
                          src={adsData['IMG_4']}
                          thumbnail
                          className={currentImgNumber === 4 ? 'border border-dark ads-view-small-image' : 'ads-view-small-image'}
                          onClick={() => adsImgClick(4)}
                        />
                      </Col>)
                  }
                  {
                    adsData['IMG_5'] && (
                      <Col xs={2} md={2} >
                        <Image
                          src={adsData['IMG_5']}
                          thumbnail
                          className={currentImgNumber === 5 ? 'border border-dark ads-view-small-image' : 'ads-view-small-image'}
                          onClick={() => adsImgClick(5)}
                        />
                      </Col>)
                  }
                  <Col xs={1} md={1} >
                    <IoIosArrowForward className='ads-image-arrow' onClick={nextImg} />
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={1}></Col>
                  <Col md={10} className='ads-main-des card'>
                    {
                      adsData['AD_TYPE'] === "Trading" ? (
                        <h5 className='left-text'>Price :&emsp;{adsData['trading_price']} AED</h5>
                      ) : (
                        <div>
                          {adsData['price_day'] && (
                            <h5 className='left-text'>Price Day:&emsp;{adsData['price_day']} AED</h5>
                          )}
                          {adsData['price_week'] && (
                            <h5 className='left-text'>Price Week:&emsp;{adsData['price_week']} AED</h5>
                          )}
                          {adsData['price_month'] && (
                            <h5 className='left-text'>Price Month:&emsp;{adsData['price_month']} AED</h5>
                          )}
                        </div>
                      )
                    }
                    <p className='left-text'><span className='p-desc-span'>Type:</span>&emsp;{adsData['AD_TYPE']}
                      <br /><span className='p-desc-span'>Area:</span>&emsp;{adsData['AREA'] == 1 ? ("Sharjah") : ("Abu Dhabi")}
                      <br /><span className='p-desc-span'>Category:</span>&emsp;{adsData['category_name']}
                      <br /><span className='p-desc-span'>Make:</span>&emsp;{adsData['MAKE']}
                      <br /><span className='p-desc-span'>Model:</span>&emsp;{adsData['MODEL']}
                      <br /><span className='p-desc-span'>Yom:</span>&emsp;{adsData['YOM']}
                      <br /><span className='p-desc-span'>Delivery option:</span>&emsp;{adsData['PIC_DEL']}
                      {(adsData['AD_TYPE'] === "Trading") && (<p><span className='p-desc-span'>Usage (hours):</span>&emsp;{adsData['usage_hours']}</p>)}</p>
                    <h6 className='left-text'>Description</h6>
                    <p className='left-text'>{adsData['AD_DESC']}</p>
                  </Col>
                  <Col md={1}></Col>
                </Row>
              </Col>
              <Col className='' md={4}>
                <Card>
                  <div className='ads-contact-data-div-box'>
                    <p className='left-text'>&emsp;Ad posted by <span className='ad-post-by'>{adsData['fname'] + " " + adsData['lname']}</span></p>
                  </div>
                  <div className='ads-contact-data-div-box'>
                    <p className='left-text'><a onClick={() => whatsappClick(adsData['mobileCode'] + adsData['MOB'])} className='ads-contact-a'>&emsp;<FaWhatsapp className='ads-contact-icons' />&emsp;{adsData['MOB']}</a></p>
                  </div>
                  {adsData['TEL_NO'] !== "" && (
                    <div className='ads-contact-data-div-box'>
                      <p className='left-text'><a onClick={() => callClick(adsData['telCode'] + adsData['TEL_NO'])} className='ads-contact-a'>&emsp;<FaPhoneAlt className='ads-contact-icons' />&emsp;{adsData['TEL_NO']}</a></p>
                    </div>
                  )}
                  <div className='ads-view-fav'>
                    {
                      myFavorite ? (
                        <button className='btn btn-warning' onClick={() => adMyFavorite(true)} >
                          <MdFavorite className='ads-fav-icon' />
                        </button>) : (
                        <button className='btn btn-warning' onClick={() => adMyFavorite(false)} >
                          <MdFavoriteBorder className='ads-fav-icon' />
                        </button>)
                    }
                  </div>
                </Card>
                <br />
                <Card className="p-3" style={{ border: '1px solid #d1e7dd', borderRadius: '8px' }}>
                  <div className="d-flex align-items-center mb-2">
                    <FaShieldAlt style={{ color: '#5cb85c', fontSize: '24px' }} />
                    <h5 className="ms-2 mb-0">Stay Alert: Avoid Online Scams</h5>
                  </div>
                  <Card.Text>
                    <ul className="mb-2" style={{ paddingLeft: '20px' }}>
                      <li>Never share card details or OTPs, and always verify items in person before payment.</li>
                      <li>machinerysouk does not offer a delivery service. Stay vigilant!</li>
                      <a href='/disclaimer'>See more</a>
                    </ul>
                  </Card.Text>
                </Card>
                {adsData.isActive == 0 && (
                  <Card className="p-3" style={{ border: '1px solid #f7cad0', borderRadius: '8px' }}>
                    <div className="d-flex align-items-center mb-2">
                      <h5 className="ms-2 mb-0">Approve Advertisement</h5>
                    </div>
                    <Card.Body>
                      <Row>
                        <Col lg={6} xs={6}>
                          <button onClick={() => approveAd(adsData.id, adsData.fname, adsData.EMAIL)} className='btn w-100 btn-success view-details' ><IoIosCheckmarkCircle className='admin-icon-size' /></button>
                        </Col>
                        <Col lg={6} xs={6}>
                          <button onClick={() => rejectAd(adsData.id, adsData.fname, adsData.EMAIL)} className='btn w-100 btn-danger view-details ' ><IoMdCloseCircle className='admin-icon-size' /></button>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>)}
                <br />
                {adsData.isActive == 2 && (
                  <Card className="p-3" style={{ border: '1px solid #f7cad0', borderRadius: '8px' }}>
                    <div className="d-flex align-items-center mb-2">
                      <h5 className="ms-2 mb-0">Advertisement Remove Option</h5>
                    </div>
                    <Card.Body>
                      <Row>
                        <Col lg={12} xs={12}>
                          <button onClick={() => deleteAd(adsData['id'])} className='btn w-100 btn-danger view-details ' ><IoTrashBin className='admin-icon-size' /></button>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>)}
              </Col>
            </Row>
          </div>)
        }
        <br />
      </div>
      <div id="admin_ads_view" className="mobile-view">
        {loading ? (<Spinner animation="border" />) : (
          <div className='ad-view-container full-ads-div'>
            <Row>
              <Col md={8} xs={10}>
                <Row>
                  <Col md={1}></Col>
                  <Col md={10}>
                    <p className='ads-title-left'>{adsData['AD_TITLE']}</p>
                  </Col>
                  <Col md={1}></Col>
                </Row>
              </Col>
              <Col className="d-flex flex-column align-items-end" md={4} xs={2}>
                <FaArrowAltCircleLeft className='ads-icons' onClick={goBack} />
              </Col>
            </Row>
            <Row className="justify-content-center align-items-center" >
              <Col md={10} className="card d-flex justify-content-end ads-mobile-div-color">
                <p className='main-image-count-text'>{currentImgNumber + "/" + imgCount}</p>
                <Image src={mainImgUrl} fluid className='ads-view-main-image' />
              </Col>
            </Row>
            <Row className="justify-content-center mt-3">
              <Col xs={1} md={1} >
                <IoIosArrowBack className='ads-image-mobile-arrow' onClick={previousImg} />
              </Col>
              {
                adsData['IMG_1'] && (
                  <Col xs={2} md={2} >
                    <Image
                      src={adsData['IMG_1']}
                      thumbnail
                      className={currentImgNumber === 1 ? 'border border-dark ads-mobile-view-small-image' : 'ads-mobile-view-small-image'}
                      onClick={() => adsImgClick(1)}
                    />
                  </Col>
                )
              }
              {
                adsData['IMG_2'] && (
                  <Col xs={2} md={2} >
                    <Image
                      src={adsData['IMG_2']}
                      thumbnail
                      className={currentImgNumber === 2 ? 'border border-dark ads-mobile-view-small-image' : 'ads-mobile-view-small-image'}
                      onClick={() => adsImgClick(2)}
                    />
                  </Col>)
              }
              {
                adsData['IMG_3'] && (
                  <Col xs={2} md={2} >
                    <Image
                      src={adsData['IMG_3']}
                      thumbnail
                      className={currentImgNumber === 3 ? 'border border-dark ads-mobile-view-small-image' : 'ads-mobile-view-small-image'}
                      onClick={() => adsImgClick(3)}
                    />
                  </Col>)
              }
              {
                adsData['IMG_4'] && (
                  <Col xs={2} md={2} >
                    <Image
                      src={adsData['IMG_4']}
                      thumbnail
                      className={currentImgNumber === 4 ? 'border border-dark ads-mobile-view-small-image' : 'ads-mobile-view-small-image'}
                      onClick={() => adsImgClick(4)}
                    />
                  </Col>)
              }
              {
                adsData['IMG_5'] && (
                  <Col xs={2} md={2} >
                    <Image
                      src={adsData['IMG_5']}
                      thumbnail
                      className={currentImgNumber === 5 ? 'border border-dark ads-mobile-view-small-image' : 'ads-mobile-view-small-image'}
                      onClick={() => adsImgClick(5)}
                    />
                  </Col>)
              }
              <Col xs={1} md={1} >
                <IoIosArrowForward className='ads-image-mobile-arrow' onClick={nextImg} />
              </Col>
            </Row>
            <br />
            <Row>
              <Col md={1}></Col>
              <Col className='card ads-main-des'>
                <br />
                {
                adsData['AD_TYPE'] === "Trading" ? (
                  <h5 className='left-text'>Price :&emsp;{adsData['trading_price']} AED</h5>
                ) : (
                  <div>
                    {adsData['price_day'] && (
                      <h5 className='left-text'>Price Day:&emsp;{adsData['price_day']} AED</h5>
                    )}
                    {adsData['price_week'] && (
                      <h5 className='left-text'>Price Week:&emsp;{adsData['price_week']} AED</h5>
                    )}
                    {adsData['price_month'] && (
                      <h5 className='left-text'>Price Month:&emsp;{adsData['price_month']} AED</h5>
                    )}
                  </div>
                )
                }
                <p className='left-text'><span className='p-desc-span'>Type:</span>&emsp;{adsData['AD_TYPE']}
                  <br /><span className='p-desc-span'>Area:</span>&emsp;{adsData['AREA'] == 1 ? ("Sharjah") : ("Abu Dhabi")}
                  <br /><span className='p-desc-span'>Category:</span>&emsp;{adsData['category_name']}
                  <br /><span className='p-desc-span'>Make:</span>&emsp;{adsData['MAKE']}
                  <br /><span className='p-desc-span'>Model:</span>&emsp;{adsData['MODEL']}
                  <br /><span className='p-desc-span'>Yom:</span>&emsp;{adsData['YOM']}
                  <br /><span className='p-desc-span'>Delivery option:</span>&emsp;{adsData['PIC_DEL']}
                  {(adsData['AD_TYPE'] === "Trading") && (<p><span className='p-desc-span'>Usage (hours):</span>&emsp;{adsData['usage_hours']}</p>)}</p>
                <h6 className='left-text'>Description</h6>
                <p className='left-text'>{adsData['AD_DESC']}</p>
              </Col>
              <Col md={1}></Col>
            </Row>
            <Row>
              <Col className='card ads-main-des'>
                <div className='ads-contact-data-div-mobile'>
                  <p className='left-text'>&emsp;Ad posted by <span className='ad-post-by'>{adsData['fname'] + " " + adsData['lname']}</span></p>
                </div>
                <div className='ads-contact-data-div-mobile'>
                  <p className='left-text'><a onClick={() => whatsappClick(adsData['mobileCode'] + adsData['MOB'])} className='ads-contact-a'>&emsp;<FaWhatsapp className='ads-contact-icons' />&emsp;{adsData['mobileCode'] + adsData['MOB']}</a></p>
                </div>
                {adsData['TEL_NO'] && (
                  <div className='ads-contact-data-div-mobile'>
                    <p className='left-text'><a onClick={() => callClick(adsData['telCode'] + adsData['TEL_NO'])} className='ads-contact-a'>&emsp;<FaPhoneAlt className='ads-contact-icons' />&emsp;{adsData['telCode'] + adsData['TEL_NO']}</a></p>
                  </div>)}
                <div className='ads-view-fav'>
                  {
                    myFavorite ? (
                      <button className='btn btn-warning' onClick={() => adMyFavorite(true)} >
                        <MdFavorite className='ads-fav-icon' />
                      </button>) : (
                      <button className='btn btn-warning' onClick={() => adMyFavorite(false)} >
                        <MdFavoriteBorder className='ads-fav-icon' />
                      </button>)
                  }
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={1}></Col>
              <Col className='card ads-main-des' style={{ border: '1px solid #d1e7dd', borderRadius: '8px' }}>
                <div className="d-flex align-items-center mb-2">
                  <FaShieldAlt style={{ color: '#5cb85c', fontSize: '24px' }} />
                  <h5 className="ms-2 mb-0">Stay Alert: Avoid Online Scams</h5>
                </div>
                <Card.Text>
                  <ul className="mb-2" style={{ paddingLeft: '20px' }}>
                    <li>Never share card details or OTPs, and always verify items in person before payment.</li>
                    <li>machinerysouk does not offer a delivery service. Stay vigilant!</li>
                  </ul>
                </Card.Text>

              </Col>
              <Col md={1}></Col>
            </Row>
            {adsData.isActive == 0 && (
              <Row>
                <Col md={1}></Col>
                <Col className='card ads-main-des' style={{ border: '1px solid #f7cad0', borderRadius: '8px' }}>
                  <div className="d-flex align-items-center mb-2">
                    <h5 className="ms-2 mb-0">Approve Advertisement</h5>
                  </div>
                  <Card.Body>
                    <Row>
                      <Col lg={6} xs={6}>
                        <button onClick={() => approveAd(adsData.id, adsData.fname, adsData.EMAIL)} className='btn w-100 btn-success view-details' ><IoIosCheckmarkCircle className='admin-icon-size' /></button>
                      </Col>
                      <Col lg={6} xs={6}>
                        <button onClick={() => rejectAd(adsData.id, adsData.fname, adsData.EMAIL)} className='btn w-100 btn-danger view-details ' ><IoMdCloseCircle className='admin-icon-size' /></button>
                      </Col>
                    </Row>
                  </Card.Body>
                </Col>
                <Col md={1}></Col>
              </Row>)}
            {adsData.isActive == 2 && (
              <Row>
                <Col md={1}></Col>
                <Col className='card ads-main-des' style={{ border: '1px solid #f7cad0', borderRadius: '8px' }}>
                  <div className="d-flex align-items-center mb-2">
                    <h5 className="ms-2 mb-0">Advertisement Remove Option</h5>
                  </div>
                  <Card.Body>
                    <Row>
                      <Col lg={12} xs={12}>
                        <button onClick={() => deleteAd(adsData['id'])} className='btn w-100 btn-danger view-details ' ><IoTrashBin className='admin-icon-size' /></button>
                      </Col>
                    </Row>
                  </Card.Body>
                </Col>
                <Col md={1}></Col>
              </Row>)}
          </div>)
        }
        <br />
      </div>
    </Container>
  );
}

export default Admin_ads_view;
