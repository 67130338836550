import React, { useState, useEffect, useRef } from 'react'
import './disclaimer.css'
import { Col, Row } from "react-bootstrap"
import Container from "react-bootstrap/Container"
import LocalIP from "../../LocalIP"
import axios from "axios"

const Disclaimer = () => {
  return (
    <Container>
      <br />
      <div id="disclaimer" className="web-view">
        <h1>Disclaimer</h1><br />
        <p>Welcome to MACHINERY SOUK.COM. We are a platform that serves as an intermediary to connect heavy machinery owners, traders, and potential contractors and traders or and end users interested in purchasing or renting machinery. Our goal is to help you find and select the right machine while enabling you to negotiate the best possible price.</p>
        <br />
        <p>
          By continuing to browse and use this website, you agree to comply with and be bound by the following terms and conditions of use, which, together with our privacy policy, govern MACHINERY SOUK.COM's relationship with you concerning this website. If you disagree with any part of these terms and conditions, please do not use our website.
        </p>
        <h4>Eligibility</h4>
        <p>
          You may not use the Website or accept these Terms if:
        </p><br />
        <ol>
          <li>You are not at least eighteen (18) years of age.</li>
          <li>You are barred or legally prohibited from receiving or using the Website under the laws of your country of residence or from where you access the Website.</li>
        </ol>

        <p>
          You are also responsible for ensuring that all persons using the Website through your network are aware of and comply with these Terms and other applicable terms and conditions.
        </p>
        <br />
        <h4>Intermediary Role</h4>
        <p>
          MACHINERY SOUK.COM ("the Website") acts as an intermediary, connecting machinery owners with contractors, traders, and end users. We provide a platform to market and promote available machinery, enabling contractors and traders to find the right machine at competitive prices. The "Details" hosted on our website are provided by machinery owners and/or agents who have been verified by us. However, while we strive to ensure the reliability of this information, you are responsible for conducting your own inquiries. We provide no guarantee and accept no responsibility for the accuracy, completeness, or reliability of any information provided in the descriptions on the Website.
        </p><br />
        <h4>Limitation of Liability</h4>
        <p>
          MACHINERY SOUK.COM acts solely as a platform for connecting parties involved in machinery transactions. All transactions, rental agreements, or contracts facilitated through the Website are conducted directly between the parties involved. MACHINERY SOUK.COM and its representatives are not parties to these agreements and do not assume any responsibility for their execution, enforcement, or performance. We disclaim any liability for any discrepancies, damages, disputes, or losses arising from machinery, rental terms, or agreements made between users.
        </p>
        <p>
          To the fullest extent permitted by law, MACHINERY SOUK.COM shall not be liable for any direct, indirect, incidental, special, or consequential damages arising from the use of the Website or from any agreements, transactions, or contracts facilitated through the Website. This includes any errors, omissions, or issues that arise from the terms and conditions agreed upon by the parties. Any disputes or claims are the sole responsibility of the users involved.
        </p><br />
        <h4>Physical Inspection and Documentation</h4>
        <p>
          We strongly advise conducting a physical inspection of any machinery before entering into a purchase or rental agreement to ensure it meets your expectations and is in the condition described. Additionally, the renting or selling party must provide valid registration and ownership certificates before finalizing any agreement to verify ownership and comply with legal requirements.
        </p><br />
        <h4>No Legal Advice</h4>
        <p>
          The draft agreements and other materials provided on the Website are for preliminary use only. They should not be construed as legal advice or a substitute for professional legal counsel. We strongly recommend that all parties engage their own legal professionals to draft, review, and finalize their agreements according to their specific needs and circumstances.
        </p><br />
        <h4>Parties' Responsibility</h4>
        <p>
          Each party involved in a transaction is responsible for ensuring that their terms and conditions are accurately reflected in the final agreement. The Website cannot offer specific advice or alterations to these drafts. Parties should thoroughly review and edit the draft agreements to fit their requirements before entering into any contract.
        </p><br />
        <h4>Indemnification</h4>
        <p>
          You agree to indemnify, defend, and hold harmless MACHINERY SOUK.COM, its officers, directors, employees, and agents from and against any and all claims, liabilities, damages, losses, or expenses, including reasonable attorneys' fees, arising out of or related to your use of the Website or any agreements made using the Website’s services.
        </p><br />
        <h4>Changes to Disclaimer</h4>
        <p>
          MACHINERY SOUK.COM reserves the right to update or modify this disclaimer at any time without prior notice. Your continued use of the Website constitutes your acceptance of the updated terms.
        </p><br />
        <h4>Content on the Website</h4>
        <p>
          The content and materials appearing on the Website may include technical, typographical, or photographic errors. We do not warrant that any of the materials on the Website are accurate, complete, or current. We may make changes to the materials contained or displayed on the Website at any time without notice.
        </p><br />
        <h4>Prohibited Conduct on the Website</h4>
        <p>
          When using the Website, you must refrain from any actions that we consider inappropriate, unlawful, or prohibited by applicable laws. This includes, but is not limited to, the following:
        </p><br />
        <ul>
          <li>Using any automated tools, software, or processes to access, retrieve, scrape, or index the Website or its content.</li>
          <li>Attempting to interfere with the proper functioning of the Website through any device, software, or process.</li>
          <li>Engaging in activities that place unreasonable or excessive demands on our infrastructure, leading to disproportionate Website usage.</li>
          <li>Attempting to decipher, decompile, disassemble, or reverse engineer any software that is part of the Website.</li>
          <li>Using or indexing any content or data from the Website for the purpose of:
            <ul>
              <li>Building or populating a searchable database of machinery.</li>
              <li>Compiling a database containing information of Machines.</li>
              <li>Competing with us in any way that has not been specifically authorized by us.</li>
            </ul>
          </li>
          <li>Transmitting spam, chain letters, contests, junk emails, surveys, or other forms of mass messaging, whether commercial or otherwise.</li>
          <li>Using the Website or its content in any way that we deem unreasonable or outside of the intended purpose.</li>
          <li>Violating any person’s rights, including copyright, trade secrets, privacy rights, or other intellectual property or proprietary rights.</li>
          <li>Impersonating any person or entity or attempting to solicit money, passwords, or personal information from others.</li>
          <li>Breaching any terms, conditions, or laws applicable to the Website.</li>
          <li>Reproducing, republishing, retransmitting, modifying, adapting, distributing, translating, creating derivative works or adaptations, publicly displaying, selling, or exploiting the Website or its content without our explicit authorization.</li>
          <li>Transmitting or attempting to transmit computer viruses, worms, defects, or other destructive items.</li>
          <li>Gaining or attempting to gain unauthorized access to the Website, its server, or any connected server, computer, or database.</li>
          <li>Initiating or attempting to initiate a denial-of-service attack or a distributed denial-of-service attack on the Website.</li>
        </ul>
        <p>We reserve the right to implement measures to prevent unauthorized access or use of the Website, which may include technological barriers or reporting any unauthorized or illegal conduct to the relevant authorities or entities.</p>
        <br />
        <h4>Our Responsibility for Loss or Damage</h4>
        <p>
          To the fullest extent permitted by law, we will not be responsible for any loss or damage you may suffer, whether arising from contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, as a result of:
        </p>
        <ol>
          <li>Your use or inability to use the Website.</li>
          <li>Your reliance on any content displayed on the Website.</li>
        </ol>

        <p>We will not be liable for any loss or damage resulting from:</p>
        <ol>
          <li>Software failures, internet errors, unavailability, or any other circumstances beyond our reasonable control.</li>
          <li>Loss of your password or account due to a breakdown, error, power failure, or other issues affecting your computer system and/or account.</li>
          <li>Your use or inability to use our Website.</li>
          <li>Your reliance on any content or information displayed on the Website.</li>
          <li>Any direct, consequential, special, or punitive loss, damage, costs, or expenses.</li>
          <li>Loss of profit, business, reputation, goodwill, or data corruption/damage.</li>
        </ol>

        <p>
          Unless expressly agreed in writing, you agree not to use the Website for any commercial or business purposes. We do not guarantee that the Website will be secure or free from errors, bugs, or viruses. We are not liable for any loss or damage caused by viruses, distributed denial-of-service attacks, or other technologically harmful material that may affect your computer equipment, programs, data, or proprietary materials due to your use of the Website or downloading of any content from it, or any website linked to it. You are responsible for configuring your own technology, programs, and platform to access the Website and should use your own virus protection software.
        </p>

        <h4>Inquiry on Property</h4>
        <p>
          If you inquire about a property on this Website, you agree that your details will be sent via email or other communication means, such as WhatsApp, directly to the agent, estate agent, landlord, or developer marketing the property. We are not liable for any communications you receive from the estate agent, landlord, developer, or any third party following your inquiry.
        </p>

        <h4>Content on the Website</h4>
        <p>
          The materials on the Website may include technical, typographical, or photographic errors. We do not warrant that the materials are accurate, complete, or up to date. We reserve the right to make changes to the materials displayed on the Website at any time, without prior notice.
        </p>

        <h4>Cookies and Data Usage</h4>
        <p>
          This website uses cookies to monitor browsing preferences. If you allow cookies to be used, certain personal information may be stored by us for use by third parties. For more details, refer to our privacy policy.
        </p>

        <h4>Warranty Disclaimer</h4>
        <p>
          Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness, or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors, and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.
        </p>

        <h4>Intellectual Property</h4>
        <p>
          This website contains material that is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance, and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions. All trademarks reproduced in this website, which are not the property of or licensed to the operator, are acknowledged on the website.
        </p>

        <h4>User Obligations</h4>
        <p>
          Registered users of MACHINERY SOUK.COM are obligated to post only machinery that is available for sale or lease. The machinery posted on the website must fairly represent its image and accurately correspond with the description provided. Any misleading or inaccurate listings are prohibited. Unauthorized use of this website, including the posting of inaccurate or fraudulent listings, may give rise to a claim for damages and/or constitute a criminal offense.
        </p>

        <h4>External Links</h4>
        <p>
          From time to time, this website may include links to other websites. These links are provided for your convenience to offer further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).
        </p>

        <h4>Governing Law</h4>
        <p>
          This disclaimer and any disputes arising from or related to the Website shall be governed by and construed in accordance with the laws of the United Arab Emirates, without regard to its conflict of law principles.
        </p>

      </div>
      <div id="disclaimer" className="mobile-view">

      </div>
    </Container>
  );
}

export default Disclaimer;
