// Footer.js
import React from 'react';
import '../footer/footer.css';
import { Container, Row, Col } from 'react-bootstrap';
import { FaPhoneAlt, FaHome, FaEnvelope, FaFacebook, FaTwitter, FaLinkedinIn, FaTiktok, FaInstagram } from 'react-icons/fa'
import { TbWorld } from 'react-icons/tb'

const Footer = () => {
  return (
    <footer>
      <div className="footer text-light p-4">
        <Container>
          <br />
          <div id="page" className="web-view">
            <Row className='py-3'>
              <Col className="text-left" md={7}>
                <h5>ABOUT</h5>
                <p className='footer-about-p site-text-color'>At Machinery Souk.com, we recognize the complexities of navigating the heavy machinery trading and rental industry, especially in a constantly evolving market. Founded by Tharindu Hashan Eknathgedara, a seasoned professional from Sri Lanka, our company was born from the need to seamlessly connect machinery owners, traders, and contractors across United Arab Emirates.</p>
                <ul className="list-unstyled">
                  <li><FaHome className="contact-icon footer-contact-icons" /><span className='site-text-color'>Plot no 3634 Al Sajaa Industrial City , Sharjah, United Arab Emirates.</span></li>
                  <li><a href="mailto:support@machinerysouk.com" className="d-flex align-items-center footer-contact-a site-text-color"><FaEnvelope className="contact-icon footer-contact-icons" />Email: support@machinerysouk.com</a></li>
                  <li><a href="tel:+971502164440" className="d-flex align-items-center footer-contact-a site-text-color"><FaPhoneAlt className="contact-icon footer-contact-icons" /> Phone: +971 50216 4440</a></li>
                </ul>
              </Col>
              <Col className="text-left" md={3} >
                <div style={{ marginLeft: "30%" }}>
                  <h5>CATEGORIES</h5>
                  <ul className="list-unstyled">
                    <li><a href="/all_ads?type=Rental&filter=false" className="footer-link site-text-color">Rental</a></li>
                    <li><a href="/all_ads?type=Trading&filter=false" className="footer-link site-text-color">Trading</a></li>
                    <li><a href="/all_ads?type=Service&filter=false" className="footer-link site-text-color">Services</a></li>
                  </ul>
                </div>
              </Col>
              <Col className="text-left" md={2}>
                <h5>QUICK LINKS</h5>
                <ul className="list-unstyled">
                  <li><a href="/" className="footer-link site-text-color">Home</a></li>
                  <li><a href="/about-us" className="footer-link site-text-color">About Us</a></li>
                  <li><a href="/all_ads" className="footer-link site-text-color">All Ads</a></li>
                </ul>
              </Col>
            </Row>
            <hr />
            <div>
              <Row className="d-flex align-items-center">
                <Col md={6} className="text-left">
                  <p className="mb-0 site-text-color">Copyright © {new Date().getFullYear()} All Rights Reserved by <a href='https://www.linkedin.com/company/wizolve-technologies/' className='copyright-a'>Wizolve Technologies (Pvt) Ltd</a></p>
                </Col>
                <Col md={6} className="text-right d-flex justify-content-end align-items-center">
                  <a href='https://www.facebook.com/profile.php?id=61559031284308' target="_blank"><FaFacebook className="contact-icon footer-social-icons" /></a>
                  <a href='https://www.instagram.com/machinery_souk?igsh=MThxcGt6a2RmZzljaQ==' target="_blank"><FaInstagram className="contact-icon footer-social-icons" /></a>
                  <a href='https://www.tiktok.com/@nuqtat.al.jabel.h?_t=8q6O4KcmJ5b&_r=1' target="_blank"><FaTiktok className="contact-icon footer-social-icons" /></a>
                  <a href='https://www.linkedin.com/company/machinery-souk/' target="_blank"><FaLinkedinIn className="contact-icon footer-social-icons" /></a>
                </Col>
              </Row>
            </div>
          </div>
          <div id="page" className="mobile-view">
            <Row className='py-3'>
              <Col className="text-left" md={7}>
                <h5>ABOUT</h5>
                <p className='footer-about-p site-text-color'>At Machinery Souk.com, we recognize the complexities of navigating the heavy machinery trading and rental industry, especially in a constantly evolving market. Founded by Tharindu Hashan Eknathgedara, a seasoned professional from Sri Lanka, our company was born from the need to seamlessly connect machinery owners, traders, and contractors across United Arab Emirates.</p>
                <ul className="list-unstyled">
                  <li><FaHome className="contact-icon footer-contact-icons" /><span className='site-text-color'>Plot no 3634 Al Sajaa Industrial City , Sharjah, United Arab Emirates.</span></li>
                  <li><a href="mailto:support@machinerysouk.com" className="d-flex align-items-center footer-contact-a site-text-color"><FaEnvelope className="contact-icon footer-contact-icons" />Email: support@machinerysouk.com</a></li>
                  <li><a href="tel:+971502164440" className="d-flex align-items-center footer-contact-a site-text-color"><FaPhoneAlt className="contact-icon footer-contact-icons" /> Phone: +971 50216 4440</a></li>
                </ul>
              </Col>
              <Col className="text-left" md={3} >
                <h5>CATEGORIES</h5>
                <ul className="list-unstyled">
                  <li><a href="/all_ads?type=Rental&filter=false" className="footer-link site-text-color">Rental</a></li>
                  <li><a href="/all_ads?type=Trading&filter=false" className="footer-link site-text-color">Trading</a></li>
                  <li><a href="/all_ads?type=Service&filter=false" className="footer-link site-text-color">Services</a></li>
                </ul>
              </Col>
              <Col className="text-left" md={2}>
                <h5>QUICK LINKS</h5>
                <ul className="list-unstyled">
                  <li><a href="/" className="footer-link site-text-color">Home</a></li>
                  <li><a href="/about-us" className="footer-link site-text-color">About Us</a></li>
                  <li><a href="/all_ads" className="footer-link site-text-color">All Ads</a></li>
                </ul>
              </Col>
            </Row>
            <hr />
            <div>
              <Row className="d-flex align-items-center">
                <Col md={6} className="text-left">
                  <p className="mb-0 site-text-color">Copyright © {new Date().getFullYear()} All Rights Reserved by <a href='https://www.linkedin.com/company/wizolve-technologies/' className='copyright-a'>Wizolve Technologies (Pvt) Ltd</a></p>
                </Col>
                <Col md={6} className="text-right d-flex justify-content-end align-items-center">
                  <a href='https://www.facebook.com/profile.php?id=61559031284308' target="_blank"><FaFacebook className="contact-icon footer-social-icons" /></a>
                  <a href='https://www.instagram.com/machinery_souk?igsh=MThxcGt6a2RmZzljaQ==' target="_blank"><FaInstagram className="contact-icon footer-social-icons" /></a>
                  <a href='https://www.tiktok.com/@nuqtat.al.jabel.h?_t=8q6O4KcmJ5b&_r=1' target="_blank"><FaTiktok className="contact-icon footer-social-icons" /></a>
                  <a href='https://www.linkedin.com/company/machinery-souk/' target="_blank"><FaLinkedinIn className="contact-icon footer-social-icons" /></a>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </footer>
  );
}

export default Footer;
