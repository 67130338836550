import React, { useState, useEffect } from 'react';
import '../home/home.css';
import { Carousel, Form, FormControl, Button, InputGroup, Row, Col, Card } from 'react-bootstrap';
import Container from "react-bootstrap/Container";
import banner_1 from "../../assets/banner_1.jpg"
import banner_2 from "../../assets/banner_2.jpg"
import banner_3 from "../../assets/banner_3.jpg"
import banner_4 from "../../assets/banner_4.jpg"
import banner_5 from "../../assets/banner_5.jpg"
import banner_6 from "../../assets/banner_6.jpg"
import banner_7 from "../../assets/banner_7.jpg"
import { FaSearch, FaRegStar } from 'react-icons/fa'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LocalIP from "../../LocalIP";
import axios from "axios"
import { useHistory } from 'react-router-dom';
import { type } from '@testing-library/user-event/dist/type';

const Home = () => {

    const [machinery, setMachinery] = useState([]);
    const [area, setArea] = useState([]);
    const [services, setServices] = useState([]);
    const [search, setSearch] = useState('');
    const [categorySearch, setCategorySearch] = useState('');
    const [areaSearch, setAreaSearch] = useState('');
    const [typeSearch, setTypeSearch] = useState('');
    const [allCategory, setAllCategory] = useState([{ 'name': 'First Select Looking For' }]);

    useEffect(() => onReload(), []);
    const history = useHistory();

    const onReload = () => {
        console.log("new")
        const url = LocalIP + "machinery/";
        axios.get(url).then((response) => {
            console.log(response["data"])
            setMachinery(response["data"])
        });
        const url1 = LocalIP + "machinery/area/";
        axios.get(url1).then((response) => {
            console.log(response["data"])
            setArea(response["data"])
        });
        const url2 = LocalIP + "machinery/services/";
        axios.get(url2).then((response) => {
            console.log(response["data"])
            setServices(response["data"])
        });
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        autoplay: true,
        slidesToScroll: 1
    };

    const setTypeForm = (e) => {
        setTypeSearch(e.target.value)
        if (e.target.value == "Rental" || e.target.value == "Trading") {
            setAllCategory(machinery)
        } else {
            setAllCategory(services)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        var searchData = "/all_ads?"
        if (search !== "") {
            searchData = searchData + "search=" + search + "&"
        }
        if (typeSearch !== "") {
            searchData = searchData + "type=" + typeSearch + "&"
        }
        if (categorySearch !== "") {
            searchData = searchData + "category=" + categorySearch + "&"
        }
        if (areaSearch !== "") {
            searchData = searchData + "area=" + areaSearch + "&"
        }
        searchData = searchData + "filter=false"
        history.push(searchData);
    };

    return (
        <div>
            <div id="home" className="web-view">
                <div className='home-carousel'>
                    <Carousel>
                        <Carousel.Item>
                            <img
                                className="d-block w-100 carousel-image zoom"
                                src={banner_1}
                                alt="First slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100 carousel-image zoom"
                                src={banner_2}
                                alt="Second slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100 carousel-image zoom"
                                src={banner_3}
                                alt="Third slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100 carousel-image zoom"
                                src={banner_4}
                                alt="Fourth slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100 carousel-image zoom"
                                src={banner_5}
                                alt="Fifth slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100 carousel-image zoom"
                                src={banner_6}
                                alt="Sixth slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100 carousel-image zoom"
                                src={banner_7}
                                alt="Seventh slide"
                            />
                        </Carousel.Item>
                    </Carousel>
                </div>
                <br />
                <div className="top-search-bar-container">
                    <Form className="d-flex" onSubmit={handleSubmit}>
                        <Form.Select aria-label="Type select" className="me-2 home-select-items" value={typeSearch} onChange={e => { setTypeForm(e) }}>
                            <option value="">Looking for?</option>
                            <option value="Rental">Rental</option>
                            <option value="Service">Service</option>
                            <option value="Trading">Trading</option>
                        </Form.Select>
                        <Form.Select aria-label="Category select" className="me-2 home-select-items" value={categorySearch} onChange={e => { setCategorySearch(e.target.value) }}>
                            <option value="">Category</option>
                            {
                                allCategory.map((res, index) =>
                                    <option value={res.id}>{res.name}</option>
                                )
                            }
                        </Form.Select>
                        <Form.Select aria-label="Area select" className="me-2 home-select-items" value={areaSearch} onChange={e => { setAreaSearch(e.target.value) }}>
                            <option value="">Area</option>
                            {
                                area.map((res, index) =>
                                    <option value={res.id}>{res.a_name}</option>
                                )
                            }
                        </Form.Select>
                        <InputGroup>
                            <FormControl
                                type="text"
                                placeholder="Search"
                                aria-label="Search"
                                value={search} onChange={e => { setSearch(e.target.value) }}
                            />
                            <button type='submit' variant="warning" className='btn btn-warning' >
                                <FaSearch />
                            </button>
                        </InputGroup>
                    </Form>
                </div>
                <br />
                <Container>
                    <h2 className="search-bar-heading">Machinery Rentals & Trading</h2>
                    <div className="slider-container">
                        <Slider {...settings}>
                            {
                                machinery.map((res, index) => res.name !== "Other" && (
                                    <a href={'/all_ads?category=' + res.id}>
                                        <Card className="carousel-card">
                                            <Card.Img variant="top" src={res.image} className='machinery-img' alt='Category 1' />
                                            <Card.Body>
                                                <Card.Title className="text-center">{res.name}</Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </a>))
                            }
                        </Slider>
                    </div>
                    <br />
                    <br /><br />
                    <h2 className="search-bar-heading">Machinery Services & Repairs</h2>
                    <div className="slider-container">
                        <Slider {...settings}>
                            {
                                services.map((res, index) => res.name !== "Other" && (
                                    <a href={'/all_ads?services=' + res.id}>
                                        <Card className="carousel-card">
                                            <Card.Img variant="top" src={res.image} className='machinery-img' alt='Category 1' />
                                            <Card.Body>
                                                <Card.Title className="text-center">{res.name}</Card.Title>
                                            </Card.Body>
                                        </Card>
                                    </a>)
                                )
                            }
                        </Slider>
                    </div>
                    <br /><br /><br />
                </Container>
            </div>
            <div id="home" className="mobile-view">
                <Carousel>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 carousel-image zoom"
                            src={banner_1}
                            alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 carousel-image zoom"
                            src={banner_2}
                            alt="Second slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 carousel-image zoom"
                            src={banner_3}
                            alt="Third slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 carousel-image zoom"
                            src={banner_4}
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 carousel-image zoom"
                            src={banner_5}
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 carousel-image zoom"
                            src={banner_6}
                        />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            className="d-block w-100 carousel-image zoom"
                            src={banner_7}
                            alt="Third slide"
                        />
                    </Carousel.Item>
                </Carousel>
                <Container>
                    <div className="search-bar-container">
                        <Form onSubmit={handleSubmit}>
                            <Row className="mb-3">
                                <Col xs={12} md={12} lg={12}>
                                    <select aria-label="Category select" className='form-control form-control-select' value={typeSearch} onChange={e => { setTypeForm(e) }}>
                                        <option value="">Looking for?</option>
                                        <option value="Rental">Rental</option>
                                        <option value="Service">Service</option>
                                        <option value="Trading">Trading</option>
                                    </select>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs={12} md={12} lg={12}>
                                    <select aria-label="Category select" className='form-control form-control-select' value={categorySearch} onChange={e => { setCategorySearch(e.target.value) }}>
                                        <option>Category</option>
                                        {
                                            allCategory.map((res, index) =>
                                                <option value={res.id}>{res.name}</option>
                                            )
                                        }
                                    </select>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs={12} md={12} lg={12}>
                                    <select aria-label="Area select" className='form-control form-control-select' value={areaSearch} onChange={e => { setAreaSearch(e.target.value) }}>
                                        <option>Area</option>
                                        {
                                            area.map((res, index) =>
                                                <option value={res.id}>{res.a_name}</option>
                                            )
                                        }
                                    </select>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12} lg={12}>
                                    <InputGroup>
                                        <FormControl
                                            type="text"
                                            placeholder="Search"
                                            aria-label="Search"
                                            value={search}
                                            onChange={e => { setSearch(e.target.value) }}
                                        />
                                        <Button variant="warning" type='submit' >
                                            <FaSearch />
                                        </Button>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                    <h2 className="search-bar-heading">Machinery Rentals & Trading</h2>
                    <div className="slider-container">
                        <Carousel interval={3000} pause="hover">
                            {
                                machinery.map((res, index) => res.name !== "Other" && (
                                    <Carousel.Item href={'/all_ads?category=' + res.id}>
                                        <a href={'/all_ads?category=' + res.id}>
                                            <Card className="carousel-card">
                                                <Card.Img variant="top" src={res.image} className='machinery-img' alt='Category 1' />
                                                <Card.Body>
                                                    <Card.Title className="text-center">{res.name}</Card.Title>
                                                </Card.Body>
                                            </Card>
                                        </a>
                                    </Carousel.Item>)
                                )
                            }
                        </Carousel>
                    </div>
                    <h2 className="search-bar-heading">Machinery Services & Repairs</h2>
                    <div className="slider-container">
                        <Carousel interval={3000} pause="hover">
                            {
                                services.map((res, index) => res.name !== "Other" && (
                                    <Carousel.Item href={'/all_ads?services=' + res.id}>
                                        <a href={'/all_ads?services=' + res.id}>
                                            <Card className="carousel-card">
                                                <Card.Img variant="top" src={res.image} className='machinery-img' alt='Category 1' />
                                                <Card.Body>
                                                    <Card.Title className="text-center">{res.name}</Card.Title>
                                                </Card.Body>
                                            </Card>
                                        </a>
                                    </Carousel.Item>)
                                )
                            }
                        </Carousel>
                    </div>
                </Container>
                <br />
            </div>
        </div>
    );
}

export default Home;
