import React, { useState, useEffect } from 'react';
import '../register/register.css';
import Container from "react-bootstrap/Container";
import { Carousel, Form, Button, Row, Col, Card } from 'react-bootstrap';
import { FaFacebook, FaGoogle } from 'react-icons/fa'
import reg_back from "../../assets/reg-back.png"
import LocalIP from "../../LocalIP";
import axios from "axios"
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom"
import { GoogleLogin } from 'react-google-login'
import { LoginSocialFacebook } from "reactjs-social-login";

const Register = () => {

  let history = useHistory();
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fnameError, setFnameError] = useState('');
  const [lnameError, setLnameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  useEffect(() => userCheck(), []);

  const userCheck = () => {
    if (localStorage.getItem("loginAccess")) {
      history.push("/");
    } else {
    }
  }

  const clearAll = () => {
    setFname('')
    setLname('')
    setEmail('')
    setPassword('')
    setFnameError('')
    setLnameError('')
    setEmailError('')
    setPasswordError('')
  }

  const validation = () => {
    var Error = false;

    if (fname === "") {
      setFnameError("First Name Required!")
      Error = true;
    } else {
      setFnameError("")
    }

    if (lname === "") {
      setLnameError("Last Name Required!")
      Error = true;
    } else {
      setLnameError("")
    }

    if (email === "") {
      setEmailError("Email Address Required!")
      Error = true;
    } else {
      setEmailError("")
    }

    if (password === "") {
      setPasswordError("Password Required!")
      Error = true;
    } else {
      setPasswordError("")
    }

    if (Error) {
      return false;
    }

    return true;
  };

  const SubmitForm = async (e) => {
    e.preventDefault();
    if (validation()) {

      const url = LocalIP + "user/Register";
      const data = JSON.stringify({
        fname: fname,
        lname: lname,
        email: email,
        password: password
      });
      console.log(data);
      await axios
        .post(url, data, {
          headers: { "Content-Type": "application/json" },
        })
        .then(async (res) => {
          console.log(res.data);
          if (res.data.success === "success") {
            toast.success("Registration Successfull!", {
              position: "top-right",
            });
            clearAll()
          } else if (res.data.err.includes("email_error") || res.data.err.includes("for key 'users.email'")) {
            toast.error("Email Already Exists!", {
              position: "top-right",
            });
          }
          history.push("/login")
        });

    }
  };

  const handleSuccess = async (response) => {
    console.log('Google Login Success:', response);
    console.log(response.wt.rV);
    var sessionTime = "24h"
    const url = LocalIP + "user/googleLogin";
    const data = JSON.stringify({
      email: response.wt.cu,
      fname: response.wt.rV,
      lname: response.wt.uT,
      googleId: response.wt.NT,
      sessionTime: sessionTime
    });
    console.log(data);
    await axios
      .post(url, data, {
        headers: { "Content-Type": "application/json" },
      })
      .then(async (res) => {
        console.log(res.data);
        if (res.data.err === "connection") {
          toast.error("Network Error!", {
            position: "top-right",
          });
        } else if (res.data.err === "user_email") {
          toast.error("Email Is Wrong!", {
            position: "top-right",
          });
        } else if (res.data.err === "user_active") {
          toast.error("Activation Failed!", {
            position: "top-right",
          });
        } else if (res.data.err === "user_password") {
          toast.error("Password Is Wrong!", {
            position: "top-right",
          });
        } else if (res.data.err === "try") {
          toast.success("Do you need a login first using an email & password!", {
            position: "top-right",
          });
        } else {
          localStorage.setItem("fname", res.data.fname);
          localStorage.setItem("lname", res.data.lname);
          localStorage.setItem("email", res.data.email);
          localStorage.setItem("id", res.data.id);
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("privilege", res.data.privilege);
          localStorage.setItem("loginAccess", true);
          localStorage.setItem("telCode", res.data.telCode);
          localStorage.setItem("TEL_NO", res.data.TEL_NO);
          localStorage.setItem("mobileCode", res.data.mobileCode);
          localStorage.setItem("MOB", res.data.MOB);
          const current = new Date();
          current.setTime(current.getTime() + 24 * 60 * 60 * 1000)
          localStorage.setItem("loginDate", current);
          history.push("/")
          window.location.reload(true)
        }
      });
  };

  const handleFailure = (response) => {
    console.log('Google Login Failure:', response);
  };

  return (
    <Container>
      <br />
      <div id="register" className="web-view">
        <Row>
          <Col className='col-6'>
            <div className="card-view-login hero">
              <Form noValidate onSubmit={SubmitForm} >
                <h1 className="mb-4">Register</h1><br/>
                
                <Row>
                  <Col style={{ textAlign: "end" }}>
                    <LoginSocialFacebook
                      appId="974160194400294"
                      onResolve={(response) => {
                        console.log(response);
                      }}
                      onReject={(error) => {
                        console.log(error);
                      }}
                    >
                      <FaFacebook className='login-icons' style={{ cursor: "pointer" }} />
                    </LoginSocialFacebook>
                  </Col>
                  <Col style={{ textAlign: "start" }}>
                    <GoogleLogin
                      clientId="14743745219-ek9kmnjmlnmnn0pss29jgcti1oqeo6os.apps.googleusercontent.com"
                      onSuccess={handleSuccess}
                      onFailure={handleFailure}
                      cookiePolicy={'single_host_origin'}
                      render={renderProps => (
                        <FaGoogle className='login-icons' onClick={renderProps.onClick} style={{ cursor: "pointer" }} />)}
                    />
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="formFirstName">
                      <Form.Label className="left-label">First Name</Form.Label>
                      <Form.Control required type="text" placeholder="Enter First Name" value={fname} onChange={e => { setFname(e.target.value) }} />
                      <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{fnameError}</div>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="formLastName">
                      <Form.Label className="left-label">Last Name</Form.Label>
                      <Form.Control required type="text" placeholder="Enter Last Name" value={lname} onChange={e => { setLname(e.target.value) }} />
                      <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{lnameError}</div>
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group controlId="formBasicEmail" className="mt-3">
                  <Form.Label className="left-label">Email address</Form.Label>
                  <Form.Control required type="email" placeholder="Enter email" value={email} onChange={e => { setEmail(e.target.value) }} />
                  <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{emailError}</div>
                </Form.Group>
                <Form.Group controlId="formBasicPassword" className="mt-3">
                  <Form.Label className="left-label">Password</Form.Label>
                  <Form.Control required type="password" placeholder="Password" value={password} onChange={e => { setPassword(e.target.value) }} />
                  <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{passwordError}</div>
                </Form.Group>
                <br />
                <Button type="submit" className="mt-4 w-100 btn btn-warning">
                  Register
                </Button>
                <br />
                <br />
                <h6>Already have an account?</h6>
                <a href="/login" className="mt-4 w-100 btn btn-dark">
                  Login
                </a>
              </Form>
            </div>
          </Col>
          <Col className='col-6'>
            <img
              className="reg-img"
              src={reg_back}
            />
          </Col>
        </Row>
        <br />
      </div>
      <div id="register" className="mobile-view">
        <div className="card-view-login-mobile hero">
          <Form noValidate onSubmit={SubmitForm} >
            <h2 className="mb-4">Register</h2>
            <Row>
              <Col style={{ textAlign: "end" }}>
                <LoginSocialFacebook
                  appId="974160194400294"
                  onResolve={(response) => {
                    console.log(response);
                  }}
                  onReject={(error) => {
                    console.log(error);
                  }}
                >
                  <FaFacebook className='login-icons' style={{ cursor: "pointer" }} />
                </LoginSocialFacebook>
              </Col>
              <Col style={{ textAlign: "start" }}>
                <GoogleLogin
                  clientId="14743745219-ek9kmnjmlnmnn0pss29jgcti1oqeo6os.apps.googleusercontent.com"
                  onSuccess={handleSuccess}
                  onFailure={handleFailure}
                  cookiePolicy={'single_host_origin'}
                  render={renderProps => (
                    <FaGoogle className='login-icons' onClick={renderProps.onClick} style={{ cursor: "pointer" }} />)}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col md={6}>
                <Form.Group controlId="formFirstName">
                  <Form.Label className="left-label">First Name</Form.Label>
                  <Form.Control required type="text" placeholder="Enter First Name" value={fname} onChange={e => { setFname(e.target.value) }} />
                  <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{fnameError}</div>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formLastName">
                  <Form.Label className="left-label">Last Name</Form.Label>
                  <Form.Control required type="text" placeholder="Enter Last Name" value={lname} onChange={e => { setLname(e.target.value) }} />
                  <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{lnameError}</div>
                </Form.Group>
              </Col>
            </Row>
            <Form.Group controlId="formBasicEmail" className="mt-3">
              <Form.Label className="left-label">Email address</Form.Label>
              <Form.Control required type="email" placeholder="Enter email" value={email} onChange={e => { setEmail(e.target.value) }} />
              <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{emailError}</div>
            </Form.Group>
            <Form.Group controlId="formBasicPassword" className="mt-3">
              <Form.Label className="left-label">Password</Form.Label>
              <Form.Control required type="password" placeholder="Password" value={password} onChange={e => { setPassword(e.target.value) }} />
              <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{passwordError}</div>
            </Form.Group>
            <br />
            <Button type="submit" className="mt-4 w-100 btn btn-warning">
              Register
            </Button>
            <br />
            <br />
            <h6>Already have an account?</h6>
            <a href="/login" className="mt-4 w-100 btn btn-dark">
              Login
            </a>
          </Form>
        </div>
        <br />
      </div>
    </Container>
  );
}

export default Register;
