import React, { useState, useEffect, useRef } from 'react';
import './my_ads.css';
import { Row, Col, Form, Button, Card, InputGroup, FormControl, Spinner } from 'react-bootstrap';
import Container from "react-bootstrap/Container";
import LocalIP from "../../LocalIP";
import axios from "axios"
import { ToastContainer, toast } from "react-toastify"
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import 'react-toastify/dist/ReactToastify.css';
import { BiSolidDetail } from "react-icons/bi";

const DeleteConfirmation = ({ onConfirm, onCancel }) => (
  <div>
    <p className='alertText'>Are you sure you want to delete?</p>
    <button className='btn btn-danger' onClick={onConfirm}>Confirm</button>
    <button className='btn btn-success alert-cancel-btn' onClick={onCancel}>Cancel</button>
  </div>
);

const My_ads = () => {

  const [allads, setAllads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(12);
  const [totalAds, setTotalAds] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [pagesCount, setPagesCount] = useState(1);

  useEffect(() => onReload(), []);

  const onReload = () => {
    console.log("new")
    const url1 = LocalIP + "ads/user_my_ads";
    axios.get(url1, {
      headers: { "Content-Type": "application/json" }, params: {
        user_id: localStorage.getItem("id"),
        start: 0,
        length: pageSize
      }
    }).then((response) => {
      console.log(response["data"])
      setAllads(response["data"])
      setLoading(false)
    });
    const url2 = LocalIP + "ads/user_total";
    axios.get(url2, {
      headers: { "Content-Type": "application/json" }, params: {
        user_id: localStorage.getItem("id")
      }
    }).then((response) => {
      console.log(response["data"])
      setTotalAds(response["data"][0].total_rows)
      setPagesCount(Math.ceil(response["data"][0].total_rows / pageSize))
      console.log(Math.ceil(response["data"][0].total_rows / pageSize))
      setLoading(false)
    });
  };

  const pageData = (page, size) => {
    console.log(page, size)
    setLoading(true)
    if (page == 1) {
      page = 0;
    } else {
      page = (page - 1) * size
    }
    const url1 = LocalIP + "ads/user_my_ads";
    axios.get(url1, {
      headers: { "Content-Type": "application/json" }, params: {
        user_id: localStorage.getItem("id"),
        start: page,
        length: size
      }
    }).then((response) => {
      console.log(response["data"])
      setAllads(response["data"])
      setLoading(false)
    });
    if (size !== pageSize) {
      setPageNum(1)
      const url2 = LocalIP + "ads/user_total";
      axios.get(url2, {
        headers: { "Content-Type": "application/json" }, params: {
          user_id: localStorage.getItem("id")
        }
      }).then((response) => {
        console.log(response["data"])
        setTotalAds(response["data"][0].total_rows)
        setPagesCount(Math.ceil(response["data"][0].total_rows / size))
        console.log(Math.ceil(response["data"][0].total_rows / size))
        setLoading(false)
      });
    }
  }

  const pageSelect = (num) => {
    setPageNum(num)
  }

  const paginationPrint = (count) => {
    const listItems = [];
    for (let i = 1; i <= count; i++) {
      if (((i <= (pageNum + 2)) && (i >= (pageNum - 2))) || (pageNum === 1 && (i <= (pageNum + 4))) || (pageNum === 2 && (i <= (pageNum + 3))) || (pageNum === pagesCount && (i >= (pageNum - 4))) || (pageNum === pagesCount - 1 && (i >= (pageNum - 3)))) {
        listItems.push(<li className={i === pageNum ? 'page-item selected-page' : 'page-item'}>
          <a class="text-dark page-link" onClick={() => { pageSelect(i); pageData(i, pageSize); }} >{i}</a>
        </li>);
      }
    }
    return listItems;
  };

  const removeMyAds = async (id) => {
    const toastId = toast(
      <DeleteConfirmation
        onConfirm={() => { confirmDelete(id); toast.dismiss(toastId); }}
        onCancel={() => toast.dismiss(toastId)}
      />,
      {
        position: "top-center",
        autoClose: false,
        closeOnClick: false,
        draggable: false,
      }
    );
  };

  const confirmDelete = async (id) => {
    const url = LocalIP + "ads/removeMyAds";
    console.log(url)
    await axios
      .delete(url, {
        headers: { "Content-Type": "application/json" }, params: {
          id: id
        }
      })
      .then(async (res) => {
        console.log(res.data);
        if (res.data.success === "success") {
          toast.success("Remove Favorite!", {
            position: "top-right",
          });
          onReload()
        }
      });
  };

  return (
    <div>
      <Container>
        <br />
        <div id="my_ads" className="web-view">
          <h4 className='text-left'>My ads</h4>
          {loading ? (<Spinner animation="border" />) : totalAds !== 0 ? (
            <div>
              <Row><p className='text-left' style={{ marginLeft: '10px' }}>Showing {pageNum == 1 ? (1) : ((pageNum - 1) * pageSize)}-{pageSize > totalAds ? (totalAds) : (pageSize * pageNum < totalAds ? (pageSize * pageNum) : (totalAds))} of {totalAds}</p></Row>
              <Row>
                {allads.map((res, index) =>
                  <Col xs={12} md={6} lg="auto">
                    {res.isActive == 2 ? (
                      <Card className="my-ads-card-view">
                        <Card.Img variant="top" className='all-ads-img' src={res.IMG_1} />
                        <Card.Body>
                          <Card.Text>
                            <p className="card-title">{res.AD_TITLE}</p>
                            <p>{res.AD_TYPE}<br />{res.category_name}<br />{res['price_day'] && (
                              "D " + res['price_day'] + "AED "
                            )}
                              {res['price_week'] && (
                                "W " + res['price_week'] + "AED "
                              )}
                              {res['price_month'] && (
                                "M " + res['price_month'] + "AED"
                              )}</p>
                          </Card.Text>
                          <Row>
                            <Col md={6} xs={6}>
                              <a className='btn btn-warning view-details' href={"/Ads_view/" + res.id}><BiSolidDetail /> Details</a>
                            </Col>
                            <Col md={3} xs={3}>
                              <a className='btn btn-success view-details' href={"/edit_ads/" + res.id} ><FaEdit /></a>
                            </Col>
                            <Col md={2} xs={2}>
                              <button className='btn btn-danger view-details' onClick={() => removeMyAds(res.id)}><MdDelete /></button>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>) : (
                        res.isActive == 0 ? (
                      <Card className="my-ads-card-view">
                        <Card.Img variant="top" className='all-ads-img' src={res.IMG_1} />
                        <Card.Body>
                          <Card.Text>
                            <p className="card-title">{res.AD_TITLE}</p>
                            <p>{res.AD_TYPE}<br />{res.category_name}<br />{res['price_day'] && (
                              "D " + res['price_day'] + "AED "
                            )}
                              {res['price_week'] && (
                                "W " + res['price_week'] + "AED "
                              )}
                              {res['price_month'] && (
                                "M " + res['price_month'] + "AED"
                              )}</p>
                          </Card.Text>
                          <Row>
                            <Col md={6} xs={6}>
                              <a className='btn btn-warning view-details' href={"/Ads_view/" + res.id}><BiSolidDetail /> Details</a>
                            </Col>
                            <Col md={3} xs={3}>
                              <a className='btn btn-success view-details' href={"/edit_ads/" + res.id} ><FaEdit /></a>
                            </Col>
                            <Col md={2} xs={2}>
                              <button className='btn btn-danger view-details' onClick={() => removeMyAds(res.id)}><MdDelete /></button>
                            </Col>
                          </Row>
                        </Card.Body>
                        <div className="dark-overlay">
                          <div className="overlay-text">Ad Pending</div>
                        </div>
                      </Card>):(<Card className="my-ads-card-view">
                        <Card.Img variant="top" className='all-ads-img' src={res.IMG_1} />
                        <Card.Body>
                          <Card.Text>
                            <p className="card-title">{res.AD_TITLE}</p>
                            <p>{res.AD_TYPE}<br />{res.category_name}<br />{res['price_day'] && (
                              "D " + res['price_day'] + "AED "
                            )}
                              {res['price_week'] && (
                                "W " + res['price_week'] + "AED "
                              )}
                              {res['price_month'] && (
                                "M " + res['price_month'] + "AED"
                              )}</p>
                          </Card.Text>
                          <Row>
                            <Col md={6} xs={6}>
                              <a className='btn btn-warning view-details' href={"/Ads_view/" + res.id}><BiSolidDetail /> Details</a>
                            </Col>
                            <Col md={3} xs={3}>
                              <a className='btn btn-success view-details' href={"/edit_ads/" + res.id} ><FaEdit /></a>
                            </Col>
                            <Col md={2} xs={2}>
                              <button className='btn btn-danger view-details' onClick={() => removeMyAds(res.id)}><MdDelete /></button>
                            </Col>
                          </Row>
                        </Card.Body>
                        <div className="dark-overlay">
                          <div className="overlay-text-red">Ad Rejected</div>
                        </div>
                      </Card>))}
                  </Col>
                )
                }
                {(allads.length != 12 && allads.length != 24 && allads.length != 60) && (<Col></Col>)}
              </Row>
              <br />
              <Row>
                <Col>
                  <ul class="pagination pagination-l">
                    <li className={pageNum === 1 ? 'page-item disabled' : 'page-item'} >
                      <a class="text-dark page-link" onClick={() => { pageSelect(pageNum - 1); pageData(pageNum - 1, pageSize); }}>Previous</a>
                    </li>
                    {paginationPrint(pagesCount)}
                    <li className={pageNum === pagesCount ? 'page-item disabled' : 'page-item'} >
                      <a class="text-dark page-link" onClick={() => pageSelect(pageNum + 1)}>Next</a>
                    </li>
                  </ul>
                </Col>
                <Col>
                  <div className='d-flex flex-column align-items-end'>
                    <Form.Control as="select" className='form-control-ads-page-size' value={pageSize} onChange={e => { setPageSize(e.target.value); pageData(1, e.target.value) }}>
                      <option value="12">12</option>
                      <option value="24">24</option>
                      <option value="60">60</option>
                    </Form.Control>
                  </div>
                </Col>
              </Row>
            </div>) : (<h1>You don't have any posted ads</h1>)}
        </div>
      </Container>
      <div className='mobile-view-container'>
        <div id="my_ads" className="mobile-view">
          <h4 className='text-left'>My ads</h4>
          {loading ? (<Spinner animation="border" />) : totalAds !== 0 ? (<div>
            <Row><p className='text-left' style={{ marginLeft: '10px' }}>Showing {pageNum == 1 ? (1) : ((pageNum - 1) * pageSize)}-{pageSize > totalAds ? (totalAds) : (pageSize * pageNum < totalAds ? (pageSize * pageNum) : (totalAds))} of {totalAds}</p></Row>
            <Row>
              {
                allads.map((res, index) =>
                  <Col xs={12} md={6} lg="auto">
                    {res.isActive == 2 ? (
                      <Card className="my-ads-card-view">
                        <Card.Img variant="top" className='all-ads-img' src={res.IMG_1} />
                        <Card.Body>
                          <Card.Text>
                            <p className="card-title">{res.AD_TITLE}</p>
                            <p>{res.AD_TYPE}<br />{res.category_name}<br />{res['price_day'] && (
                              "D " + res['price_day'] + "AED "
                            )}
                              {res['price_week'] && (
                                "W " + res['price_week'] + "AED "
                              )}
                              {res['price_month'] && (
                                "M " + res['price_month'] + "AED"
                              )}</p>
                          </Card.Text>
                          <Row>
                            <Col md={6} xs={6}>
                              <a className='btn btn-warning view-details' href={"/Ads_view/" + res.id}><BiSolidDetail /> Details</a>
                            </Col>
                            <Col md={3} xs={3}>
                              <a className='btn btn-success view-details' href={"/edit_ads/" + res.id} ><FaEdit /></a>
                            </Col>
                            <Col md={2} xs={2}>
                              <button className='btn btn-danger view-details' onClick={() => removeMyAds(res.id)}><MdDelete /></button>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>) : (
                      res.isActive == 0 ? (
                        <Card className="my-ads-card-view">
                          <Card.Img variant="top" className='all-ads-img' src={res.IMG_1} />
                          <Card.Body>
                            <Card.Text>
                              <p className="card-title">{res.AD_TITLE}</p>
                              <p>{res.AD_TYPE}<br />{res.category_name}<br />{res['price_day'] && (
                                "D " + res['price_day'] + "AED "
                              )}
                                {res['price_week'] && (
                                  "W " + res['price_week'] + "AED "
                                )}
                                {res['price_month'] && (
                                  "M " + res['price_month'] + "AED"
                                )}</p>
                            </Card.Text>
                            <Row>
                              <Col md={6} xs={6}>
                                <a className='btn btn-warning view-details' href={"/Ads_view/" + res.id}><BiSolidDetail /> Details</a>
                              </Col>
                              <Col md={3} xs={3}>
                                <a className='btn btn-success view-details' href={"/edit_ads/" + res.id} ><FaEdit /></a>
                              </Col>
                              <Col md={2} xs={2}>
                                <button className='btn btn-danger view-details' onClick={() => removeMyAds(res.id)}><MdDelete /></button>
                              </Col>
                            </Row>
                          </Card.Body>
                          <div className="dark-overlay">
                            <div className="overlay-text">Ad Pending</div>
                          </div>
                        </Card>
                        ) : (
                        <Card className="my-ads-card-view">
                          <Card.Img variant="top" className='all-ads-img' src={res.IMG_1} />
                          <Card.Body>
                            <Card.Text>
                              <p className="card-title">{res.AD_TITLE}</p>
                              <p>{res.AD_TYPE}<br />{res.category_name}<br />{res['price_day'] && (
                                "D " + res['price_day'] + "AED "
                              )}
                                {res['price_week'] && (
                                  "W " + res['price_week'] + "AED "
                                )}
                                {res['price_month'] && (
                                  "M " + res['price_month'] + "AED"
                                )}</p>
                            </Card.Text>
                            <Row>
                              <Col md={6} xs={6}>
                                <a className='btn btn-warning view-details' href={"/Ads_view/" + res.id}><BiSolidDetail /> Details</a>
                              </Col>
                              <Col md={3} xs={3}>
                                <a className='btn btn-success view-details' href={"/edit_ads/" + res.id} ><FaEdit /></a>
                              </Col>
                              <Col md={2} xs={2}>
                                <button className='btn btn-danger view-details' onClick={() => removeMyAds(res.id)}><MdDelete /></button>
                              </Col>
                            </Row>
                          </Card.Body>
                          <div className="dark-overlay">
                            <div className="overlay-text-red">Ad Rejected</div>
                          </div>
                        </Card>)
                    )}
                  </Col>
                )
              }
              {(allads.length != 12 && allads.length != 24 && allads.length != 60) && (<Col></Col>)}
            </Row>
            <br />
            <Row>
              <Col>
                <ul class="pagination pagination-l">
                  <li className={pageNum === 1 ? 'page-item disabled' : 'page-item'} >
                    <a class="text-dark page-link" onClick={() => { pageSelect(pageNum - 1); pageData(pageNum - 1, pageSize); }}>Previous</a>
                  </li>
                  {paginationPrint(pagesCount)}
                  <li className={pageNum === pagesCount ? 'page-item disabled' : 'page-item'} >
                    <a class="text-dark page-link" onClick={() => pageSelect(pageNum + 1)}>Next</a>
                  </li>
                </ul>
              </Col>
              <Col>
                <div className='d-flex flex-column align-items-end'>
                  <Form.Control as="select" className='form-control-ads-page-size' value={pageSize} onChange={e => { setPageSize(e.target.value); pageData(1, e.target.value) }}>
                    <option value="12">12</option>
                    <option value="24">24</option>
                    <option value="60">60</option>
                  </Form.Control>
                </div>
              </Col>
            </Row>
          </div>) : (<h1>You don't have any posted ads</h1>)}
        </div>
      </div>
    </div>
  );
}

export default My_ads;
