
import "./App.css"
import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Navbar from "../src/Components/navbar/navbar";
import "./../node_modules/bootstrap/dist/css/bootstrap.min.css";
import Footer from "../src/Components/footer/footer.js";
import Home from "../src/Components/Pages/home/home.js";
import Login from "../src/Components/Pages/login/login.js";
import Register from "../src/Components/Pages/register/register.js";
import Post_ads from "../src/Components/Pages/post-ads/post_ads.js"
import Edit_ads from "../src/Components/Pages/edit-ads/edit_ads.js"
import All_ads from "../src/Components/Pages/all-ads/all_ads.js"
import Pending_ads from "../src/Components/Pages/pending-ads/pending_ads.js"
import Ads_view from "../src/Components/Pages/ads-view/ads_view.js"
import Admin_ads_view from "../src/Components/Pages/admin-ads-view/admin_ads_view.js"
import My_ads from "../src/Components/Pages/my-ads/my_ads.js"
import My_favorite from "../src/Components/Pages/my-favorite/my_favorite.js"
import Forgot_password from "../src/Components/Pages/forgot-password/forgot_password.js"
import Save_password from "../src/Components/Pages/save-password/save_password.js"
import Disclaimer from "../src/Components/Pages/disclaimer/disclaimer.js"
import About_us from "../src/Components/Pages/about-us/about_us.js"
import Profile_settings from "../src/Components/Pages/profile-settings/profile_settings.js"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import check from "./Components/main/loginCheck.js"
import { gapi } from 'gapi-script';

const clientId = "14743745219-ek9kmnjmlnmnn0pss29jgcti1oqeo6os.apps.googleusercontent.com";

function App() {
  useEffect(() => {
    check();
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope: ""
      })
    };

    gapi.load('client:auth2', start);
  }, []);

  return (
    <BrowserRouter>
      <div className="overlay">

        <Navbar />
        <ToastContainer toastStyle={{ backgroundColor: "#26272B", color: "#fff", marginTop: "80px" }} />
        <div className="App" style={{ minHeight: 85 + "vh" }}>
          <Switch>
            <Route path="/forgot_password" render={() => <Forgot_password />} />
            <Route path="/profile_settings" render={() => <Profile_settings />} />
            <Route path="/about_us" render={() => <About_us />} />
            <Route path="/disclaimer" render={() => <Disclaimer />} />
            <Route path="/my_ads" render={() => <My_ads />} />
            <Route path="/my_favorite" render={() => <My_favorite />} />
            <Route path="/Ads_view/:id" component={Ads_view} />
            <Route path="/Admin_ads_view/:id" component={Admin_ads_view} />
            <Route path="/save_password" component={Save_password} />
            <Route path="/all_ads" render={() => <All_ads />} />
            <Route path="/pending_ads" render={() => <Pending_ads />} />
            <Route path="/post_ads" render={() => <Post_ads />} />
            <Route path="/edit_ads/:id" component={Edit_ads} />
            <Route path="/Register" render={() => <Register />} />
            <Route path="/login" render={() => <Login />} />
            <Route path="/" render={() => <Home />} />
          </Switch>
        </div>
        <Footer />
      </div>

    </BrowserRouter>
  );
}

export default App;
