import React, { useState, useEffect, useRef } from 'react'
import './forgot_password.css'
import Container from "react-bootstrap/Container";
import { Carousel, Form, Button, Row, Col, Card } from 'react-bootstrap';
import { FaFacebook, FaGoogle } from 'react-icons/fa'
import forgot_back from "../../assets/forgot_password.png"
import { ToastContainer, toast } from "react-toastify";
import LocalIP from "../../LocalIP";
import axios from "axios"
import { useHistory } from "react-router-dom"

const Forgot_password = () => {

  let history = useHistory();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [btnDisable, setBtnDisable] = useState(false);

  const clearAll = () => {
    setEmail('')
    setEmailError('')
  }

  const validation = () => {
    var Error = false;

    if (email === "") {
      setEmailError("Email Address Required!")
      Error = true;
    } else {
      setEmailError("")
    }

    if (Error) {
      return false;
    }

    return true;
  };

  const SubmitForm = async (e) => {
    e.preventDefault();
    if (validation()) {
      setBtnDisable(true)
      const url = LocalIP + "user/forgot_password";
      await axios
        .get(url, {
          headers: { "Content-Type": "application/json" }, params: {
            email: email,
          }
        })
        .then(async (res) => {
          console.log(res.data);
          setBtnDisable(false)
          if (res.data.err === "connection") {
            toast.error("Network Error!", {
              position: "top-right",
            });
          } else if (res.data.err === "user_email") {
            toast.error("Email Is Wrong!", {
              position: "top-right",
            });
          } else if (res.data.success === "reset") {
            clearAll()
            toast.success("Password Reset Successfull,Check Your Email!", {
              position: "top-right",
            });
          } else {
            history.push("/Dash")
            window.location.reload(true)
          }
        });

    }
  };

  return (
    <Container>
      <br />
      <div id="forgot_password" className="web-view">
        <Row>
          <Col className='col-6'>
            <div className="card-view-login hero">
              <Form noValidate onSubmit={SubmitForm} >
                <br />
                <br />
                <br />
                <h1 className="mb-4">Forgot My Password</h1><br />
                <Form.Group controlId="formBasicEmail" className="mt-3">
                  <Form.Label className="left-label">Email address</Form.Label>
                  <Form.Control required type="email" placeholder="Enter email" value={email} onChange={e => { setEmail(e.target.value) }} />
                  <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{emailError}</div>
                </Form.Group>
                <br />
                <Button type="submit" className="mt-4 w-100 btn btn-warning">
                  Check
                </Button>
                <br />
                <br />
                <br />
                <h6>Remember your password?</h6>
                <a href="/Login" className="mt-4 w-100 btn btn-dark">
                  Login
                </a>
                <br />
                <br />
                <h6>Don't have an account yet?</h6>
                <a href="/register" className="mt-4 w-100 btn btn-dark">
                  Register
                </a>
              </Form>
            </div>
          </Col>
          <Col className='col-6'>
            <img
              className="login-img"
              src={forgot_back}
            />
          </Col>
        </Row>
        <br />
      </div>
      <div id="forgot_password" className="mobile-view">
        <div className="card-view-login-mobile hero">
          <Form noValidate onSubmit={SubmitForm} >
            <br />
            <br />
            <h1 className="mb-4">Forgot My Password</h1><br />
            <Form.Group controlId="formBasicEmail" className="mt-3">
              <Form.Label className="left-label">Email address</Form.Label>
              <Form.Control required type="email" placeholder="Enter email" value={email} onChange={e => { setEmail(e.target.value) }} />
              <div style={{ color: "red", textAlign: "left", marginLeft: "10px" }}>{emailError}</div>
            </Form.Group>
            <br />
            <Button type="submit" className="mt-4 w-100 btn btn-warning">
              Check
            </Button>
            <br />
            <br />
            <br />
            <h6>Remember your password?</h6>
            <a href="/Login" className="mt-4 w-100 btn btn-dark">
              Login
            </a>
            <br />
            <br />
            <h6>Don't have an account yet?</h6>
            <a href="/register" className="mt-4 w-100 btn btn-dark">
              Register
            </a>
          </Form>
        </div>
        <br />
      </div>
    </Container>
  );
}

export default Forgot_password;
